import { createSelector } from 'reselect';

export const usersDomainSelector = state => state.entities.users;

export const usersListSelector = createSelector(
  usersDomainSelector,
  substate =>
    Object.keys(substate.byId).map(id => {
      return substate.byId[id];
    }),
);
