import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Table, Thead, Tr, Th, Td, Tbody } from '@sportnet/ui/lib/Table';
import Loader from '@sportnet/ui/lib/Loader';
import { usersListDefinition } from './definitions';
import Checkbox from '@sportnet/ui/lib/Checkbox';
import TheSelectSimple from '@sportnet/ui/lib/TheSelectSimple';
import Input from '@sportnet/ui/lib/Input';
// import InfoTooltip from '@sportnet/ui/lib/InfoTooltip';
import Button from '@sportnet/ui/lib/Button';
import { __ } from '../../utilities';
import { saveUser, removeUser } from '../UserForm/actions';
import { appSpaceSelector } from '../App/selectors';
import { fetchSuccessUsers } from './actions';
import Modal, { ModalContent, ModalActions } from '@sportnet/ui/lib/Modal';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 50px;
`;

const WrapperFix = styled.div`
  > div {
    overflow-x: visible;
  }
`;

const RoleTd = styled(Td)`
  text-align: center;
`;

const SecondaryRoleTd = styled(RoleTd)``;

const RoleTh = styled(Th)`
  text-align: center;
`;

const EditButton = styled(Button)`
  margin-left: 8px;
  vertical-align: middle;
`;

// const PositionedInfoTooltip = styled.div`
//   display: inline-block;
//   position: relative;
//   top: 3px;
// `;

// const FixedInfoTooltip = ({ content }) => {
//   return (
//     <PositionedInfoTooltip>
//       <InfoTooltip content={content} />
//     </PositionedInfoTooltip>
//   );
// };

export const RolesSelect = ({ roles, selectedRole, onChange, ...props }) => {
  if (!roles) {
    return null;
  }
  if (roles.length === 1) {
    return (
      <Checkbox
        checked={roles[0].role === selectedRole}
        onChange={e => {
          onChange(e.target.checked ? roles[0].role : null, e.target.name);
        }}
        {...props}
      />
    );
  }
  const rolesOptions = roles.map(({ role, name }) => ({
    value: role,
    label: name || role,
  }));
  return (
    <div
      style={{ minWidth: '200px', display: 'inline-block', marginRight: '8px' }}
    >
      <TheSelectSimple
        value={selectedRole}
        options={rolesOptions}
        onChange={v => {
          onChange(v, props.name);
        }}
        {...props}
      />
    </div>
  );
};

const Alphabet = styled.div`
  text-align: center;
  margin-bottom: 24px;
`;

const UserDisplayNameForm = ({ displayName, onChange, onCancel }) => {
  const [value, setValue] = useState(displayName);
  return (
    <Input
      onChange={e => {
        setValue(e.target.value);
      }}
      value={value}
      placeholder={displayName}
    >
      <input />
      <Input.Button
        icon="close"
        secondary
        onClick={() => {
          onCancel();
        }}
      />
      <Input.Button
        icon="write"
        primary
        onClick={() => {
          onChange(value);
        }}
      />
    </Input>
  );
};

const UserRow = ({ item, appInfo, onChange, onRemoveConfirm }) => {
  const [user, changeUser] = useState(item);
  const changeUserRole = ({
    displayName = undefined,
    grant = undefined,
    primaryRole = undefined,
    childAppId = undefined,
    childAppRole = undefined,
  }) => {
    const currentUserRole = { ...user };
    if (displayName !== undefined) {
      currentUserRole.display_name = displayName;
    }
    if (grant !== undefined) {
      currentUserRole.grant = grant;
    }
    if (primaryRole !== undefined) {
      currentUserRole.role = primaryRole;
    }
    if (childAppId && childAppRole !== undefined) {
      const childAppsRoleIndex = (
        currentUserRole.child_apps_role || []
      ).findIndex(chr => chr.app_id === childAppId);
      if (childAppsRoleIndex >= 0) {
        // zmenime rolu
        if (childAppRole) {
          currentUserRole.child_apps_role[
            childAppsRoleIndex
          ].role = childAppRole;
        }
        // vymazeme rolu
        else {
          currentUserRole.child_apps_role.splice(childAppsRoleIndex, 1);
        }
      } else if (childAppRole) {
        currentUserRole.child_apps_role = [
          ...currentUserRole.child_apps_role,
          { app_id: childAppId, role: childAppRole },
        ];
      }
    }
    changeUser(currentUserRole);
    onChange && onChange(currentUserRole);
  };
  const [isEditingName, setIsEditingName] = useState(false);
  return (
    <Tr>
      <Td width={300}>
        {isEditingName ? (
          <UserDisplayNameForm
            displayName={user.display_name}
            onChange={displayName => {
              changeUserRole({ displayName });
              setIsEditingName(false);
            }}
            onCancel={() => setIsEditingName(false)}
          />
        ) : (
          <Fragment>
            {user.display_name}
            <EditButton icon="write" onClick={() => setIsEditingName(true)} />
          </Fragment>
        )}
        <br />
        <span style={{ fontSize: '80%', color: '#aaa' }}>{user.user_id}</span>
      </Td>
      <RoleTd>
        <Checkbox
          checked={user.grant}
          onChange={e => {
            changeUserRole({ grant: e.target.checked });
          }}
        />
      </RoleTd>
      <RoleTd>
        <RolesSelect
          roles={appInfo.roles || []}
          selectedRole={user.role}
          onChange={v => {
            if (!v) {
              // confirm modal
              onRemoveConfirm([
                `Odstrániť prístup používateľa ${user.display_name} v aplikácii ${appInfo.name}?`,
                () => {
                  changeUserRole({
                    primaryRole: null,
                  });
                },
              ]);
            } else {
              changeUserRole({
                primaryRole: v,
              });
            }
          }}
        />
      </RoleTd>
      {(appInfo.child_apps || []).map(childApp => {
        const subrole = (user.child_apps_role || []).find(
          chr => chr.app_id === childApp._id,
        );
        return (
          <SecondaryRoleTd key={`${user.user_id}-${childApp._id}`}>
            <RolesSelect
              roles={childApp.roles}
              selectedRole={subrole && subrole.role}
              onChange={v => {
                changeUserRole({
                  childAppId: childApp._id,
                  childAppRole: v,
                });
              }}
            />
          </SecondaryRoleTd>
        );
      })}
    </Tr>
  );
};

const UsersList = ({ appInfo, items, isFetching, dispatch, appSpace }) => {
  const [selectedLetter, setSelectedLetter] = React.useState();
  const alphabet = React.useMemo(() => {
    if (items && items.length > 100) {
      const abc = [
        ...items.reduce((r, user) => {
          r.add(user.display_name[0].toUpperCase());
          return r;
        }, new Set()),
      ].sort();
      setSelectedLetter(abc[0]);
      return abc;
    } else {
      setSelectedLetter('');
      return [];
    }
  }, [items, setSelectedLetter]);

  const [removeConfirm, setRemoveConfirm] = useState(null);

  if (isFetching) {
    return (
      <Wrapper>
        <Loader size="xxl" />
      </Wrapper>
    );
  }

  return (
    <WrapperFix>
      {alphabet.length > 0 && (
        <Alphabet>
          {alphabet.map(a => (
            <Button
              size="xs"
              primary={selectedLetter === a}
              key={a}
              onClick={() => setSelectedLetter(a)}
            >
              {a}
            </Button>
          ))}
        </Alphabet>
      )}
      <Table>
        <Thead>
          <Tr>
            <Th>Meno a priezvisko</Th>
            <RoleTh>Grant</RoleTh>
            <RoleTh>{appInfo.name}</RoleTh>
            {(appInfo.child_apps || []).map(childApp => {
              return <RoleTh key={childApp._id}>{childApp.name}</RoleTh>;
            })}
          </Tr>
        </Thead>
        <Tbody>
          {items
            .filter(({ temporary, display_name }) => {
              return (
                !temporary &&
                (!selectedLetter ||
                  display_name[0].toUpperCase() === selectedLetter)
              );
            })
            .sort((a, b) => a.display_name < b.display_name)
            .map((item, index) => {
              return (
                <UserRow
                  key={item.user_id}
                  item={item}
                  appInfo={appInfo}
                  onChange={changedUser => {
                    if (!changedUser.role) {
                      dispatch(
                        removeUser(changedUser.user_id, appInfo._id, appSpace),
                      );
                      const newUsers = [...items];
                      newUsers.splice(index, 1);
                      dispatch(fetchSuccessUsers(newUsers));
                    } else {
                      dispatch(
                        saveUser(
                          {
                            user_id: changedUser.user_id,
                            role: changedUser.role,
                            grant: changedUser.grant,
                            display_name: changedUser.display_name,
                            child_apps_role: changedUser.child_apps_role || [],
                          },
                          appInfo._id,
                          appSpace,
                        ),
                      );
                    }
                  }}
                  onRemoveConfirm={params => {
                    setRemoveConfirm(params);
                  }}
                />
              );
            })}
        </Tbody>
      </Table>
      {removeConfirm && (
        <Modal isOpen>
          <ModalContent>{removeConfirm[0]}</ModalContent>
          <ModalActions>
            <Button
              primary
              basic
              onClick={() => {
                setRemoveConfirm(null);
              }}
            >
              {__('Zrušiť')}
            </Button>
            <Button
              primary
              icon="trash"
              onClick={() => {
                removeConfirm[1]();
                setRemoveConfirm(null);
              }}
            >
              {__('Odstrániť')}
            </Button>
          </ModalActions>
        </Modal>
      )}
    </WrapperFix>
  );
};

UsersList.propTypes = {
  items: usersListDefinition.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  appInfo: PropTypes.shape({
    name: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
        name: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default connect(state => {
  return {
    appInfo: state.appInfo.data,
    appSpace: appSpaceSelector(state),
  };
})(UsersList);
