import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
} from './constants';

import {
  USERFORM_FETCH_SUCCESS,
  USERFORM_FETCH_FAILURE,
  USERFORM_FAILURE,
} from '../UserForm/constants';

const usersInitialState = {
  byId: {},
  isFetching: true,
};

const usersReducer = (state = usersInitialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        byId: action.payload.users.reduce((acc, user) => {
          return { ...acc, [user.user_id]: user };
        }, {}),
      };
    case USERFORM_FETCH_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.userInfo.user_id]: action.payload.userInfo,
        },
      };
    case FETCH_USERS_FAILURE:
    case USERFORM_FETCH_FAILURE:
    case USERFORM_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
