import React, { PureComponent } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TheLayout from '@sportnet/ui/lib/TheLayout';
import Sidebar from '@sportnet/ui/lib/Sidebar';
import Loader from '@sportnet/ui/lib/Loader';
import Button from '@sportnet/ui/lib/Button';
import Segment from '@sportnet/ui/lib/Segment';
import {
  ContextBar,
  ContextBarItem,
  ContextBarSpacer,
} from '@sportnet/ui/lib/ContextBar';
import HeaderBar from '@sportnet/ui/lib/HeaderBar';
import Api from '../../Api';
import UsersList from '../UsersList';
import UserForm from '../UserForm';
import { fetchAppInfo, clearErrors, fetchAppSpace } from './actions';
import { fetchUsers } from '../UsersList/actions';
import { openSidebar, closeSidebar } from '../UserForm/actions';
import NotFound from '@sportnet/ui/lib/NotFound';
import Filter from './Filter';
import { removeAccents } from 'sportnet-utilities';

import {
  accessTokenSelector,
  appIdSelector,
  appSpaceSelector,
  errorSelector,
} from './selectors';
import { usersListSelector } from '../UsersList/selectors';
import { editUserSelector } from '../UserForm/selectors';

import { usersListDefinition } from '../UsersList/definitions';

import { __ } from '../../utilities';
import { Fragment } from 'react';

const Wrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: white;
`;

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      query: { q: '' },
      filterQuery: '',
      setQueryTimeout: null,
    };
    this.setQueryTimeout = null;
    this.setQuery = this.setQuery.bind(this);
  }
  setQuery(q) {
    this.setState({ query: q });
    if (this.setQueryTimeout) {
      clearTimeout(this.setQueryTimeout);
      this.setQueryTimeout = null;
    }
    this.setQueryTimeout = setTimeout(() => {
      this.setState({ filterQuery: removeAccents(q.q).toLowerCase() });
    }, 300);
  }
  componentDidMount() {
    Api.setToken(this.props.accessToken);
    this.props.dispatch(fetchAppInfo(this.props.appId));
    this.props.dispatch(fetchAppSpace(this.props.appId, this.props.appSpace));
    this.props.dispatch(fetchUsers(this.props.appId, this.props.appSpace));
  }
  componentDidUpdate(nextProps) {
    if (nextProps.error) {
      alert(nextProps.error);
      this.props.dispatch(clearErrors());
    }
  }
  render() {
    const {
      isFetchingApp,
      isFetchingAppSpace,
      isFetchingUsers,
      users,
      editUser,
      appSpaceUser,
      appSpaceUserError,
    } = this.props;

    if (isFetchingApp || isFetchingAppSpace) {
      return (
        <Wrapper>
          <Loader size="xxl" />
        </Wrapper>
      );
    }
    if (appSpaceUserError) {
      if (appSpaceUserError.statusCode === 403) {
        return <NotFound title={__('Prístup odmietnutý')} icon="error" />;
      } else {
        return <NotFound title={appSpaceUserError.text} icon="error" />;
      }
    }
    return (
      <TheLayout
        app={this.props.appInfo}
        apps={[]}
        appSpace={appSpaceUser}
        appSpaces={[]}
        // userName={appSpaceUser.role}
        // userSubName={appSpaceUser.role}
        onClose={() => {
          this.context.onClose();
        }}
        navigation={[
          {
            label: __('Zoznam používateľov'),
          },
        ]}
        top={
          <Fragment>
            <HeaderBar>
              <HeaderBar.Header>
                {__('Používatelia aplikácie')}{' '}
                <strong>{this.props.appInfo.name}</strong> {__('v organizácii')}{' '}
                <strong>{this.props.appSpaceUser.org_profile.name}</strong>
              </HeaderBar.Header>
            </HeaderBar>
            <Filter query={this.state.query} setQuery={this.setQuery} />
          </Fragment>
        }
        bottom={
          <ContextBar>
            <ContextBarSpacer />
            <ContextBarItem>
              <Button
                primary
                icon="plus"
                onClick={() => {
                  this.props.dispatch(openSidebar());
                }}
              >
                {__('Pridať používateľa')}
              </Button>
            </ContextBarItem>
          </ContextBar>
        }
      >
        <Segment>
          <Segment raised>
            <UsersList
              items={
                this.state.filterQuery && this.state.filterQuery
                  ? users.filter(({ user_id, display_name }) => {
                      return (
                        user_id.includes(this.state.filterQuery) ||
                        removeAccents(display_name)
                          .toLowerCase()
                          .includes(this.state.filterQuery)
                      );
                    })
                  : users
              }
              isFetching={isFetchingUsers}
            />
          </Segment>
        </Segment>

        <Sidebar
          header={__('Používateľ')}
          visible={this.props.sidebarIsVisible}
          onClose={() => {
            this.props.dispatch(closeSidebar());
          }}
        >
          <Segment>
            <UserForm
              user={editUser}
              isFetching={this.props.isFetchingSidebar}
              isDone={() => {
                this.props.dispatch(closeSidebar());
                this.props.dispatch(
                  fetchUsers(this.props.appId, this.props.appSpace),
                );
              }}
            />
          </Segment>
        </Sidebar>
      </TheLayout>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  users: usersListDefinition.isRequired,
  appInfo: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  isFetchingMe: PropTypes.bool,
  isFetchingApp: PropTypes.bool,
  isFetchingUsers: PropTypes.bool.isRequired,
  isFetchingSidebar: PropTypes.bool.isRequired,
  appUser: PropTypes.shape({
    display_name: PropTypes.string,
  }),
  sidebarIsVisible: PropTypes.bool.isRequired,
  editUser: PropTypes.shape({
    display_name: PropTypes.string,
  }).isRequired,
  accessToken: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  appSpace: PropTypes.string.isRequired,
  error: PropTypes.string,
};

App.defaultProps = {
  error: null,
};

App.contextTypes = {
  onClose: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    users: usersListSelector(state),
    editUser: editUserSelector(state),
    appInfo: state.appInfo.data,
    appSpaceUser: state.appSpaceUser.data,
    appSpaceUserError: state.appSpaceUser.error,
    isFetchingApp: state.appInfo.isFetching,
    isFetchingAppSpace: state.appSpaceUser.isFetching,
    isFetchingUsers: state.entities.users.isFetching,
    isFetchingSidebar: state.userForm.isFetching,
    sidebarIsVisible: state.userForm.visible,
    accessToken: accessTokenSelector(state),
    appId: appIdSelector(state),
    appSpace: appSpaceSelector(state),
    error: errorSelector(state),
  };
};

export default connect(mapStateToProps)(App);
