import DefaultFilter from '@sportnet/ui/lib/Filter';
import PropTypes from 'prop-types';
import React from 'react';
import { __ } from '../../utilities';

const Filter = ({ setQuery, query }) => {
  const filterValue = {
    q: query.q,
  };

  return (
    <DefaultFilter
      value={filterValue}
      onChange={nextFilterValue => {
        const nextQuery = {
          q: nextFilterValue.q ? nextFilterValue.q : '',
        };
        setQuery(nextQuery);
      }}
      filters={[
        {
          type: 'query',
          name: 'q',
          placeholder: __('Zadajte meno používateľa, alebo SportnetId'),
        },
      ]}
    />
  );
};

Filter.propTypes = {
  query: PropTypes.shape({
    q: PropTypes.string.isRequired,
  }).isRequired,
  setQuery: PropTypes.func.isRequired,
};

export default Filter;
