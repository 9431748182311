const namespace = 'UserForm';

export const OPEN_SIDEBAR = `${namespace}/OPEN_SIDEBAR`;
export const CLOSE_SIDEBAR = `${namespace}/CLOSE_SIDEBAR`;

export const SET_ACTIVE_ID = `${namespace}/SET_ACTIVE_ID`;

export const USERFORM_FETCH_REQUEST = `${namespace}/USERFORM_FETCH_REQUEST`;
export const USERFORM_FETCH_SUCCESS = `${namespace}/USERFORM_FETCH_SUCCESS`;
export const USERFORM_FETCH_FAILURE = `${namespace}/USERFORM_FETCH_FAILURE`;

export const USERFORM_FAILURE = `${namespace}/USERFORM_FAILURE`;
