import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Api from '../../Api';
import { setAccessToken, setAppId, setAppSpace } from '../App/actions';
import {
  accessTokenSelector,
  appIdSelector,
  appSpaceSelector,
} from '../App/selectors';
import { paramsToObject } from '../../utilities';

class CommProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.processSingleAlienMessage = this.processSingleAlienMessage.bind(this);
    this.instanceId = paramsToObject(window.location.search).instance_id || '';
  }

  getChildContext() {
    return {
      onClose: () => {
        window.parent.postMessage([this.formatMessage('CLOSE')], '*');
      },
    };
  }

  componentDidMount() {
    window.addEventListener(
      'message',
      e => {
        if (Array.isArray(e.data)) {
          this.processAlienMessages(e.data);
        }
      },
      false,
    );
  }

  processAlienMessages(messages = []) {
    messages.forEach(this.processSingleAlienMessage);
  }

  formatMessage(type, payload) {
    return {
      instanceId: this.instanceId,
      type,
      payload,
    };
  }

  processSingleAlienMessage({ type, payload }) {
    const { dispatch } = this.props;
    try {
      switch (type) {
        case 'SET_ACCESS_TOKEN':
          if (typeof payload === 'string') {
            Api.setToken(payload);
            dispatch(setAccessToken(payload));
          } else {
            throw Error(
              `Invalid ${type} action payload "${payload}", must be string.`,
            );
          }
          break;
        case 'SET_APP_ID':
          if (typeof payload === 'string') {
            dispatch(setAppId(payload));
          } else {
            throw Error(
              `Invalid ${type} action payload "${payload}", must be string.`,
            );
          }
          break;
        case 'SET_APP_SPACE':
          if (typeof payload === 'string') {
            dispatch(setAppSpace(payload));
          } else {
            throw Error(
              `Invalid ${type} action payload "${payload}", must be string.`,
            );
          }
          break;
        default:
      }
    } catch (e) {
      window.parent.postMessage(
        [
          {
            type: 'ERROR_GENERIC',
            payload: e.message,
          },
        ],
        '*',
      );
    }
  }

  render() {
    const { accessToken, appId, appSpace } = this.props;
    if (!accessToken || !appId || !appSpace) {
      return null;
    }
    return this.props.children;
  }
}

CommProvider.propTypes = {
  accessToken: PropTypes.string,
  appId: PropTypes.string,
  appSpace: PropTypes.string,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
};

CommProvider.defaultProps = {
  accessToken: null,
  appId: null,
  appSpace: null,
};

CommProvider.childContextTypes = {
  onClose: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    accessToken: accessTokenSelector(state),
    appId: appIdSelector(state),
    appSpace: appSpaceSelector(state),
  };
};

export default connect(mapStateToProps)(CommProvider);
