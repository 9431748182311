export const __ = toTranslate => {
  return toTranslate;
};

export const paramsToObject = params => {
  const query = params.substr(1);
  const result = {};
  query.split('&').forEach(part => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};
