const namespace = 'App';

export const FETCH_APPINFO_REQUEST = `${namespace}/FETCH_APPINFO_REQUEST`;
export const FETCH_APPINFO_SUCCESS = `${namespace}/FETCH_APPINFO_SUCCESS`;
export const FETCH_APPINFO_FAILURE = `${namespace}/FETCH_APPINFO_FAILURE`;

export const SET_ACCESS_TOKEN = `${namespace}/SET_ACCESS_TOKEN`;
export const SET_APP_ID = `${namespace}/SET_APP_ID`;
export const SET_APP_SPACE = `${namespace}/SET_APP_SPACE`;

export const CLEAR_ERRORS = `${namespace}/CLEAR_ERRORS`;

export const FETCH_APPSPACEUSER_REQUEST = `${namespace}/FETCH_APPSPACEUSER_REQUEST`;
export const FETCH_APPSPACEUSER_SUCCESS = `${namespace}/FETCH_APPSPACEUSER_SUCCESS`;
export const FETCH_APPSPACEUSER_FAILURE = `${namespace}/FETCH_APPSPACEUSER_FAILURE`;
