import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import thunkMiddleware from 'redux-thunk';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import 'react-virtualized/styles.css';
import App from './containers/App';
import CommProvider from './containers/CommProvider';
import baseTheme from './theme/base';
import reducer from './reducer';
import Api from './Api';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reduxMiddleware = [thunkMiddleware];

Api.setBaseUrl(process.env.REACT_APP_API_BASE_URL);

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
  html, body, #root {
    margin: 0;
    padding: 0;
    height: 100vh;
  }
  html {
    font-size: 100%;
    font-family: 'Open Sans', sans-serif;
  }
  * {
    box-sizing: border-box;
  }
`;

const Wrapper = styled.div`
  font-family: ${props => props.theme.fontFamily};
  height: 100%;
  color: ${props => props.theme.textColor};
`;

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...reduxMiddleware)),
);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={baseTheme}>
      <Fragment>
        <GlobalStyle />
        <Wrapper>
          <CommProvider>
            <App />
          </CommProvider>
        </Wrapper>
      </Fragment>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
  () => {
    const messages = [];
    const develPrefix = 'REACT_APP_DEVEL_';
    for (const [variable, value] of Object.entries(process.env)) {
      if (variable.startsWith(develPrefix)) {
        messages.push({
          instanceId: 'devel',
          type: variable.substring(develPrefix.length),
          payload: value,
        });
      }
    }
    if (messages.length) {
      window.postMessage(messages, '*');
    }
  },
);
