import {
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  SET_ACTIVE_ID,
  USERFORM_FAILURE,
  USERFORM_FETCH_REQUEST,
  USERFORM_FETCH_SUCCESS,
  USERFORM_FETCH_FAILURE,
} from './constants';

const userFormInitialState = {
  visible: false,
  isFetching: false,
  activeId: null,
};

const userFormReducer = (state = userFormInitialState, action) => {
  switch (action.type) {
    case OPEN_SIDEBAR:
      return {
        ...state,
        visible: true,
        activeId: null,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,
        visible: false,
        isFetching: false,
      };
    case USERFORM_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    case SET_ACTIVE_ID:
      return {
        ...state,
        activeId: action.payload.id,
        visible: true,
      };
    case USERFORM_FETCH_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case USERFORM_FETCH_SUCCESS:
    case USERFORM_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default userFormReducer;
