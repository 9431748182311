import {
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  SET_ACTIVE_ID,
  USERFORM_FETCH_REQUEST,
  USERFORM_FETCH_SUCCESS,
  USERFORM_FAILURE,
} from './constants';
import Api from '../../Api';
import { fetchSuccessUsers } from '../UsersList/actions';
import { __ } from '../../utilities';

export const openSidebar = () => ({
  type: OPEN_SIDEBAR,
  payload: {},
});

export const closeSidebar = () => ({
  type: CLOSE_SIDEBAR,
  payload: {},
});

export const setActiveId = id => ({
  type: SET_ACTIVE_ID,
  payload: {
    id,
  },
});

export const setUserFormId = id => {
  return dispatch => {
    dispatch(setActiveId(id));
  };
};

export const userFormFetchRequest = () => ({
  type: USERFORM_FETCH_REQUEST,
  payload: {},
});

export const userFormFetchSuccess = userInfo => ({
  type: USERFORM_FETCH_SUCCESS,
  payload: {
    userInfo,
  },
});

export const userFormFailure = error => ({
  type: USERFORM_FAILURE,
  payload: {
    error,
  },
});

export const findUser = (id, appId, appSpace) => {
  return async (dispatch, getStore) => {
    try {
      const store = getStore();
      if (!store.entities.users.byId[id]) {
        dispatch(userFormFetchRequest());
        const res = await Api.appgrantGetProfileForUser(appId, appSpace, id);
        const user = {
          user_id: res._id,
          display_name: `${res.name} ${res.surname}`,
          role: '',
          grant: false,
          temporary: true,
        };
        dispatch(userFormFetchSuccess(user));
      }
      dispatch(setActiveId(id));
    } catch (e) {
      dispatch(
        userFormFailure({
          description: e.description,
          text: __('Nepodarilo sa získať detail používateľa'),
        }),
      );
    }
  };
};

export const removeUser = (id, appId, appSpace) => {
  return async dispatch => {
    try {
      dispatch(userFormFetchRequest());
      const res = await Api.appgrantDeleteRoleForUser(appId, appSpace, id);
      dispatch(closeSidebar());
      dispatch(fetchSuccessUsers(res.roles));
    } catch (e) {
      dispatch(
        userFormFailure({
          detail: e.detail,
          text: __('Používateľa sa nepodarilo odstrániť'),
        }),
      );
    }
  };
};

export const saveUser = (user, appId, appSpace) => {
  return async dispatch => {
    try {
      dispatch(userFormFetchRequest());
      await Api.appgrantRegisterUser(appId, appSpace, {
        data: user,
      });
      dispatch(closeSidebar());
    } catch (e) {
      dispatch(
        userFormFailure({
          description: e.description,
          text: __('Používateľa sa nepodarilo uložiť'),
        }),
      );
    }
  };
};
