import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { rem } from 'polished';
import FormGroup from '@sportnet/ui/lib/FormGroup';
import Header from '@sportnet/ui/lib/Header';
import Label from '@sportnet/ui/lib/Label/Label';
import Input from '@sportnet/ui/lib/Input';
import Button from '@sportnet/ui/lib/Button';
import Checkbox from '@sportnet/ui/lib/Checkbox';
import Loader from '@sportnet/ui/lib/Loader';
import Col, { Row } from '@sportnet/ui/lib/Grid';
import { findUser, removeUser, saveUser } from './actions';
import { appIdSelector, appSpaceSelector } from '../App/selectors';
import { __ } from '../../utilities';
import Api from '../../Api';
import { RolesSelect } from '../UsersList';

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  & > div {
    width: 100%;
  }
`;
const InputButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 ${rem(10)};
  min-height: auto;
  height: 100%;
  border-radius: 0 ${rem(4)} ${rem(4)} 0;
  span {
    margin-right: ${rem(2)};
  }
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
`;

function normalizeRoles(roles = []) {
  return roles.reduce((acc, role) => {
    acc[role.app_id] = role.role === null ? '' : role.role;
    return acc;
  }, {});
}

class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      userRolesByAppId: {},
      isInviting: false,
      submitted: false,
    };
    this.onChange = this.onChange.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.renderRoles = this.renderRoles.bind(this);
    this.onChangeChildAppRole = this.onChangeChildAppRole.bind(this);
  }

  componentDidMount() {
    const user = this.props.user || {};
    this.setState({
      user: user,
      userRolesByAppId: normalizeRoles(user.child_apps_role),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      user: nextProps.user,
      submitted: false,
      userRolesByAppId: normalizeRoles(nextProps.user.child_apps_role),
    });
  }

  onChange(v, n) {
    const user = { ...this.state.user, [n]: v };
    if (n === 'searchValue') {
      if (v.indexOf('@') >= 0) {
        user.email = v;
      } else {
        user.email = null;
      }
    }
    this.setState({
      user,
    });
  }

  onChangeChildAppRole(v, n) {
    const appId = n;
    const role = v;
    this.setState(prevState => ({
      ...prevState,
      userRolesByAppId: {
        ...prevState.userRolesByAppId,
        [appId]: role,
      },
    }));
  }

  async removeUser() {
    const confirm = window.confirm(
      __('Naozaj chcete odstrániť tohoto používateľa?'),
    );
    if (confirm) {
      await this.props.dispatch(
        removeUser(
          this.state.user.user_id,
          this.props.appId,
          this.props.appSpace,
        ),
      );
      this.props.isDone();
    }
  }

  async saveUser() {
    const {
      app: { child_apps = [] },
    } = this.props;
    const { user, userRolesByAppId } = this.state;
    const byAppId = child_apps.reduce((acc, app) => {
      acc[app._id] = true;
      return acc;
    }, {});

    const data = {
      grant: !!user.grant,
      role: user.role,
      child_apps_role: Object.keys(userRolesByAppId)
        .filter(appId => appId in byAppId)
        .map(appId => ({
          app_id: appId,
          role: userRolesByAppId[appId] || null,
        })),
    };

    // ak nemame user_id ale mame email, posleme pozvanku (skusime emailom)
    if (user.user_id) {
      await this.props.dispatch(
        saveUser(
          {
            user_id: user.user_id,
            display_name: user.display_name,
            ...data,
          },
          this.props.appId,
          this.props.appSpace,
        ),
      );
      this.props.isDone();
    } else if (user.email) {
      try {
        this.setState({ isInviting: true });
        await Api.appgrantInviteUser(this.props.appId, this.props.appSpace, {
          data: {
            email: user.email,
            ...data,
          },
        });
        this.setState({ isInviting: false });
        alert(__('Pozvánka bola odoslaná na zadaný email'));
        this.props.isDone();
      } catch (e) {
        alert(
          __(
            'Pri odosielaní pozvánky nastala chyba. Skontrolujte prosím zadaný email.',
          ),
        );
        this.setState({ isInviting: false });
      }
    }
  }

  renderRoles(roles) {
    return roles.map(role => (
      <option key={role.role} value={role.role}>
        {role.name}
      </option>
    ));
  }

  render() {
    const {
      app: { child_apps = [] },
    } = this.props;
    const { user, userRolesByAppId } = this.state;

    return (
      <React.Fragment>
        {(this.props.isFetching || this.state.isInviting) && (
          <LoaderWrapper>
            <Loader size="xxl" />
          </LoaderWrapper>
        )}
        <form
          onSubmit={e => {
            e.stopPropagation();
            e.preventDefault();
            if (!user.searchValue) {
              return;
            }
            if (user.email) {
              this.setState({
                submitted: true,
              });
            } else {
              this.props.dispatch(
                findUser(
                  user.searchValue,
                  this.props.appId,
                  this.props.appSpace,
                ),
              );
            }
          }}
        >
          <FormGroup>
            <Label>{__('Zadajte Sportnet ID alebo Email')}</Label>
            <InputWrapper>
              <Input
                disabled={user.user_id}
                name="searchValue"
                value={user.user_id || user.searchValue || ''}
                onChange={e => this.onChange(e.target.value, e.target.name)}
                size="s"
              />
              {!user.email && (
                <InputButton primary icon="search" type="submit" />
              )}
            </InputWrapper>
          </FormGroup>
        </form>
        {(this.state.submitted || user.user_id || user.email) && (
          <React.Fragment>
            {user.user_id && (
              <FormGroup>
                <Label>{__('Meno a priezvisko')}</Label>
                <Input
                  size="s"
                  name="display_name"
                  onChange={this.onChange}
                  value={user.display_name}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label>
                {this.props.app.name}: {__('Primárna rola')}
              </Label>
              <RolesSelect
                roles={this.props.app.roles}
                selectedRole={user.role}
                onChange={this.onChange}
                name="role"
              />
            </FormGroup>
            <FormGroup>
              <Label>
                {this.props.app.name}:{' '}
                {__('Právo spravovať iných používateľov')}
              </Label>
              <Checkbox
                name="grant"
                onChange={e => this.onChange(e.target.checked, 'grant')}
                checked={user.grant}
              />
            </FormGroup>
            {child_apps.length > 0 && (
              <React.Fragment>
                <Header size="xs">Prístup do podaplikácií</Header>
                {child_apps.map(app => {
                  const value =
                    app._id in userRolesByAppId
                      ? userRolesByAppId[app._id]
                      : '';
                  return (
                    <React.Fragment key={app._id}>
                      <FormGroup>
                        <Label>{app.name}</Label>
                        <RolesSelect
                          roles={app.roles}
                          selectedRole={value}
                          name={app._id}
                          onChange={this.onChangeChildAppRole}
                        />
                      </FormGroup>
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            )}

            <Row>
              {!user.temporary && user.user_id && (
                <Col xs={6}>
                  <Button basic danger block onClick={this.removeUser}>
                    Zmazať
                  </Button>
                </Col>
              )}
              <Col xs={user.temporary || !user.user_id ? 12 : 6}>
                <Button primary block onClick={this.saveUser}>
                  Uložiť
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

UserForm.propTypes = {
  user: PropTypes.shape({
    user_id: PropTypes.string,
    searchValue: PropTypes.string,
  }).isRequired,
  app: PropTypes.shape({
    _id: PropTypes.string,
    roles: PropTypes.arrayOf(
      PropTypes.shape({
        role: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  appSpace: PropTypes.string.isRequired,
  isDone: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    app: state.appInfo.data,
    appId: appIdSelector(state),
    appSpace: appSpaceSelector(state),
  };
};

export default connect(mapStateToProps)(UserForm);
