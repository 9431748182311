import { createSelector } from 'reselect';
import { usersDomainSelector } from '../UsersList/selectors';

const userFormDomainSelector = state => state.userForm;

export const editUserSelector = createSelector(
  usersDomainSelector,
  userFormDomainSelector,
  (users, userFormData) => {
    if (users.byId && users.byId[userFormData.activeId]) {
      return users.byId[userFormData.activeId];
    }
    return {};
  },
);
