import { combineReducers } from 'redux';
import usersReducer from './containers/UsersList/reducer';
import userFormReducer from './containers/UserForm/reducer';
import { appInfoReducer, appSpaceUserReducer } from './containers/App/reducer';

export default combineReducers({
  appInfo: appInfoReducer,
  appSpaceUser: appSpaceUserReducer,
  userForm: userFormReducer,
  entities: combineReducers({
    users: usersReducer,
  }),
});
