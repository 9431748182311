import PropTypes from 'prop-types';

export const userDefinition = PropTypes.shape({
  app_id: PropTypes.string.isRequired,
  app_space: PropTypes.string.isRequired,
  display_name: PropTypes.string.isRequired,
  grant: PropTypes.bool,
  roles: PropTypes.string,
  user_id: PropTypes.string.isRequired,
});

export const usersListDefinition = PropTypes.arrayOf(userDefinition);
