import {
  FETCH_APPINFO_REQUEST,
  FETCH_APPINFO_SUCCESS,
  FETCH_APPINFO_FAILURE,
  SET_ACCESS_TOKEN,
  SET_APP_ID,
  SET_APP_SPACE,
  CLEAR_ERRORS,
  FETCH_APPSPACEUSER_REQUEST,
  FETCH_APPSPACEUSER_SUCCESS,
  FETCH_APPSPACEUSER_FAILURE,
} from './constants';

import {
  USERFORM_FETCH_FAILURE,
  USERFORM_FAILURE,
} from '../UserForm/constants';

import { FETCH_USERS_FAILURE } from '../UsersList/constants';

const appInfoInitialState = {
  data: {},
  isFetching: true,
  error: null,
};

export const appInfoReducer = (state = appInfoInitialState, action) => {
  switch (action.type) {
    case FETCH_APPINFO_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_APPINFO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.appInfo,
      };
    case FETCH_APPINFO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        token: action.payload.token,
      };
    case SET_APP_ID:
      return {
        ...state,
        appId: action.payload.appId,
      };
    case SET_APP_SPACE:
      return {
        ...state,
        appSpace: action.payload.appSpace,
      };
    case USERFORM_FETCH_FAILURE:
    case USERFORM_FAILURE:
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

const appSpaceUserInitialState = {
  data: {},
  isFetching: true,
  error: null,
};

export const appSpaceUserReducer = (
  state = appSpaceUserInitialState,
  action,
) => {
  switch (action.type) {
    case FETCH_APPSPACEUSER_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_APPSPACEUSER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.appSpace,
      };
    case FETCH_APPSPACEUSER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
