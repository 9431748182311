import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
} from './constants';
import Api from '../../Api';

export const fetchRequestUsers = () => ({
  type: FETCH_USERS_REQUEST,
  payload: {},
});

export const fetchSuccessUsers = users => ({
  type: FETCH_USERS_SUCCESS,
  payload: {
    users,
  },
});

export const fetchFailureUsers = error => ({
  type: FETCH_USERS_FAILURE,
  payload: {
    error,
  },
});

export const fetchUsers = (appId, appSpace) => {
  return dispatch => {
    dispatch(fetchRequestUsers());
    Api.appgrantGetRoles(appId, appSpace)
      .then(res => {
        dispatch(fetchSuccessUsers(res.users));
      })
      .catch(e => {
        console.error(e);
        dispatch(fetchFailureUsers(e));
      });
  };
};
