import { createSelector } from 'reselect';

const appInfoDomainSelector = state => state.appInfo;

export const accessTokenSelector = createSelector(
  appInfoDomainSelector,
  appInfo => {
    return appInfo.token;
  },
);

export const appIdSelector = createSelector(appInfoDomainSelector, appInfo => {
  return appInfo.appId;
});

export const appSpaceSelector = createSelector(
  appInfoDomainSelector,
  appInfo => {
    return appInfo.appSpace;
  },
);

export const errorSelector = createSelector(
  appInfoDomainSelector,
  substate => substate.error && substate.error.text,
);
