import {
  FETCH_APPINFO_REQUEST,
  FETCH_APPINFO_SUCCESS,
  FETCH_APPINFO_FAILURE,
  FETCH_APPSPACEUSER_REQUEST,
  FETCH_APPSPACEUSER_SUCCESS,
  FETCH_APPSPACEUSER_FAILURE,
  SET_ACCESS_TOKEN,
  SET_APP_ID,
  SET_APP_SPACE,
  CLEAR_ERRORS,
} from './constants';
import Api from '../../Api';
import { __ } from '../../utilities';

export const setAccessToken = token => ({
  type: SET_ACCESS_TOKEN,
  payload: {
    token,
  },
});
export const setAppId = appId => ({
  type: SET_APP_ID,
  payload: {
    appId,
  },
});
export const setAppSpace = appSpace => ({
  type: SET_APP_SPACE,
  payload: {
    appSpace,
  },
});

export const clearErrors = () => ({
  type: CLEAR_ERRORS,
});

export const fetchRequestAppInfo = () => ({
  type: FETCH_APPINFO_REQUEST,
  payload: {},
});

export const fetchSuccessAppInfo = appInfo => ({
  type: FETCH_APPINFO_SUCCESS,
  payload: {
    appInfo,
  },
});

export const fetchFailureAppInfo = error => ({
  type: FETCH_APPINFO_FAILURE,
  payload: {
    error,
  },
});

export const fetchAppInfo = appId => {
  return dispatch => {
    dispatch(fetchRequestAppInfo());
    Api.getPublicApp(appId)
      .then(res => {
        dispatch(fetchSuccessAppInfo(res));
      })
      .catch(e => {
        dispatch(
          fetchFailureAppInfo({
            description: e.description,
            text: __('Nepodarilo sa získať informácie o aplikácii'),
          }),
        );
      });
  };
};

export const fetchRequestAppSpaceUser = () => ({
  type: FETCH_APPSPACEUSER_REQUEST,
  payload: {},
});

export const fetchSuccessAppSpaceUser = appSpace => ({
  type: FETCH_APPSPACEUSER_SUCCESS,
  payload: {
    appSpace,
  },
});

export const fetchFailureAppSpaceUser = error => ({
  type: FETCH_APPSPACEUSER_FAILURE,
  payload: {
    error,
  },
});

export const fetchAppSpace = (appId, appSpace) => {
  return dispatch => {
    dispatch(fetchRequestAppSpaceUser());
    Api.meAppInfoForAppSpace(appId, appSpace)
      .then(res => {
        dispatch(fetchSuccessAppSpaceUser(res));
      })
      .catch(async e => {
        dispatch(
          fetchFailureAppSpaceUser({
            ...(e.details || {}),
            text:
              (e.details || {}).userinfo ||
              __('Nepodarilo sa získať informácie o používateľovi'),
          }),
        );
      });
  };
};
